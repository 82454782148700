import {
  FetchResult,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import classnames from 'classnames';
import get from 'lodash/get';
import { ChangeEvent, KeyboardEvent, useState } from 'react';

import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';
import TextInput from 'components/InputFields/TextInput/TextInput';
import SpinnerInline from 'components/Spinner/SpinnerInline/SpinnerInline';
import { EMAIL_LIST_SIGN_UP } from 'data/graphql/mutations';

import { trackNewsletterSignupSuccess } from 'lib/analytics';
import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';
import Logger from 'lib/utils/Logger';

import {
  CreateEmailSignUpMutation,
  CreateEmailSignUpMutationVariables,
  EmailSignUpInput,
  Exact,
} from 'types/generated/api';

import CheckMarkIcon from 'assets/icons/ic-checkmark.inline.svg';
import RightArrowIcon from 'assets/icons/ic-chevrone-right.inline.svg';

import styles from './NewsLetterInput.module.scss';

type NewsLetterInputProps = {
  textInputClassname?: string;
};

export const NEWSLETTER_SIGNUP_ERROR_MESSAGE =
  'Something went wrong, please verify your email address.';

const LABEL_NEWSLETTER =
  'Subscribe to our newsletter and receive news about Verishop! Enter your email address then press ENTER';

const NewsLetterInput = ({ textInputClassname }: NewsLetterInputProps) => {
  const [email, setEmail] = useState('');
  const [successfulSignUp, setSuccessfulSignUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [emailSignUp, { error, loading }] = useMutation<
    CreateEmailSignUpMutation,
    CreateEmailSignUpMutationVariables
  >(EMAIL_LIST_SIGN_UP, {
    variables: {
      input: { email, subscribeToMens: true, subscribeToWomens: true },
    },
  });

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleOnKey = (
    event: KeyboardEvent,
    subscribeToEmailList: (
      options?: MutationFunctionOptions<
        CreateEmailSignUpMutation,
        Exact<{ input: EmailSignUpInput }>
      >
    ) => Promise<
      FetchResult<
        CreateEmailSignUpMutation,
        Record<string, unknown>,
        Record<string, unknown>
      >
    >
  ) => {
    if (event.key === 'Enter') {
      handleNewsLetterSignUp(subscribeToEmailList);
    }
  };

  const handleNewsLetterSignUp = async (
    subscribeToEmailList: (
      options?: MutationFunctionOptions<
        CreateEmailSignUpMutation,
        Exact<{ input: EmailSignUpInput }>
      >
    ) => Promise<
      FetchResult<
        CreateEmailSignUpMutation,
        Record<string, unknown>,
        Record<string, unknown>
      >
    >
  ) => {
    try {
      const result = await subscribeToEmailList();
      const isSuccess = get(result, 'data.createEmailSignUp.success');

      if (isSuccess) {
        setSuccessfulSignUp(true);
        setErrorMessage('');
        trackNewsletterSignupSuccess({ email });
      } else if (isSuccess === false) {
        setErrorMessage(NEWSLETTER_SIGNUP_ERROR_MESSAGE);
      }
    } catch (error) {
      // Error handling occurs in the mutation as well
      Logger.error(
        'Analytics Error: Email newsletter sign up unsuccessful',
        error
      );
    }
  };

  const renderIcon = (isLoading: boolean) => {
    if (isLoading) {
      return <SpinnerInline size={BUTTON_SIZE.SMALL} />;
    } else if (successfulSignUp) {
      return (
        <div className={styles.checkmarkIconContainer}>
          <CheckMarkIcon className={styles.checkmarkIcon} />
        </div>
      );
    }

    return (
      <RightArrowIcon
        className={classnames(styles.arrowIcon, {
          [styles.buttonEnabled]: email.length > 0,
        })}
      />
    );
  };

  if (error) {
    Logger.error('Footer error: Error signing up for newsletter', error);
  }

  return (
    <div className={styles.root}>
      <TextInput
        aria-label={LABEL_NEWSLETTER}
        className={classnames(styles.textInput, textInputClassname)}
        classNameInput={styles.textInput}
        data-test-target="textInput"
        onChange={handleEmailChange}
        onKeyUp={event => handleOnKey(event, emailSignUp)}
        placeholder="Email Address"
        type="email"
        value={email}
      />
      <div
        aria-label="Newsletter Sign Up"
        className={styles.arrowContainer}
        data-test-target="signUpButton"
        onClick={() => handleNewsLetterSignUp(emailSignUp)}
        onKeyPress={keyPressHandler({
          Enter: () => handleNewsLetterSignUp(emailSignUp),
        })}
        role="button"
        tabIndex={0}
      >
        {renderIcon(loading)}
      </div>
      {errorMessage && (
        <div
          aria-label={errorMessage}
          aria-live="polite"
          className={styles.errorText}
          role="status"
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default NewsLetterInput;
