import IDefaultComponentProps from 'components/defaultComponentProps';

import VerishopLogo from 'assets/images/logotype/verishop-logo.inline.svg';

import styles from './PoweredByVerishop.module.scss';

export const PoweredByVerishop = ({ className }: IDefaultComponentProps) => (
  <div className={className}>
    <a
      aria-label="link to Verishop main site"
      className={styles.link}
      href="https://www.verishop.com"
      rel="noreferrer"
      target="_blank"
    >
      <span>Powered by</span>
      <VerishopLogo className={styles.logo} />
    </a>
  </div>
);
