import throttle from 'lodash/throttle';
import { ReactElement, useEffect, useState } from 'react';

import { Visible } from './index';

import styles from './Grid.module.scss';

const THROTTLE_TIME_MS = 100;

/**
 * Prints out debug information pertaining to viewport width
 */
const BreakpointDebugger = (): ReactElement => {
  const [screenWidth, setScreenWidth] = useState<number>();
  const throttledSetScreenWidth = throttle(setScreenWidth, THROTTLE_TIME_MS);
  useEffect(() => {
    if (!window) {
      return;
    }
    setScreenWidth(window.innerWidth);
    window.addEventListener('resize', () =>
      throttledSetScreenWidth(window.innerWidth)
    );
  }, []);
  return (
    <div className={styles.breakpointDebugger}>
      <span>screen width: {screenWidth}px &mdash; </span>
      <Visible xs>XS breakpoint</Visible>
      <Visible sm>SM breakpoint</Visible>
      <Visible md>MD breakpoint</Visible>
      <Visible lg>LG breakpoint</Visible>
      <Visible xl>XL breakpoint</Visible>
    </div>
  );
};

export default BreakpointDebugger;
